.blog {
    align-self: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 200px;
    max-width: 100%;
}

.post {
    align-self: center;
    text-align: justify;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    border-bottom: 1px #6abaff solid;
    border-top: 1px #6abaff solid;
}

.blog-header {
    align-self: center;
    text-align: justify;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 0;
}