body {
  /* background-color: #122216; */
  background-color: #254159;
}

.App {
  text-align: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  display: flex;
  height: 100%;
  font-size: calc(10px + 2vmin);
}

.content {
  color: #dddfe2;
  flex-direction: row;
}

.title {
  color: #ffffff;
}

@media screen and (max-width: 500px) {

  .App,
  .lists {
    flex-direction: column;
  }
}

a {
  color: #6abaff;
}

code {
  background-color: #33597aa6;
  padding: .03em .4em;
  border-radius: 6px;
}