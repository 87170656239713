.nav {
    align-self: left;
    flex-direction: row;
    align-items: flex-start;
}

nav {
    flex-direction: row;
    flex: 1;
}

nav ul li {
    flex-direction: row;
    list-style: none;
    display: inline;
    padding: 2%;
}

nav ul li a {
    color: #ff99c7;
    text-decoration: none;
}