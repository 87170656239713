.headshot-container {
  display: flex;
  justify-content: center;
  /* horizontally center */
  align-items: center;
  /* vertically center */
}

.headshot {
  display: 'flex';
  max-width: 100%;
}

.left {
  float: 'left';
  flex: 1;
}

.right {
  float: 'right';
  flex: 1;
  padding: 2.5vw;
}

.header {
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
}

.lists {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.list {
  flex: 1;
  width: 100%;
  text-align: center;
}

.list ul {
  list-style-type: none;
  padding-left: 0;
}

.tagline {
  font-size: calc(10px + 3vmin);
  color: #ff99c7;
}