.papers {
    align-self: center;
    align-items: center;
    justify-content: center;
    width: 70%;
}

.papers-list {
    text-align: left;
    margin: 10px;
    font-size: calc(10px + 1.5vmin);
}

.papers-list li {
    margin-bottom: 10px;
}