.project {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    flex: 1;
    text-align: right;
}

.project h3 {
    text-align: left;
}

.project a:link {
    text-decoration: none;
}